<template>
  <div quote-resi-data-vuetify>
    <v-app class="rounded transparent">
      <v-main>
        <!-- <HelloWorld
        :service="service||''"
        :lang="lang||'en'"
        :phone="phone||''"
        :country="country||''"
        :terms="terms||country==='canada'&&lang==='fr'?'https://www.wasteconnectionscanada.com/fr/nos-services/conditions/':(country==='canada'&&lang==='en')||(country==='canada'&&!lang)?'https://www.wasteconnectionscanada.com/terms-of-use/':'https://www.wasteconnections.com/terms-of-use/'"
        :privacy="privacy||country==='canada'&&lang==='fr'?'https://www.wasteconnectionscanada.com/fr/nos-services/confidentialite/':(country==='canada'&&lang==='en')||(country==='canada'&&!lang)?'https://www.wasteconnectionscanada.com/privacy-policy/':'https://www.wasteconnections.com/privacy-policy/'"
        :name="name||'Waste Connections'" /> -->
        <RequestQuoteCmp
        :service="service||''"
        :serviceicons="serviceicons||true"
        :assigntodistrict="assigntodistrict||0"
        :storefrontDistrictCodeOverride="storefrontDistrictCodeOverride||0"
        :storefrontBypassLob="storefrontBypassLob||false"
        :lang="lang||'en'"
        :primary="primary||''"
        :secondary="secondary||''"
        :phone="phone||''"
        :country="country||''"
        :terms="terms||(country==='canada'&&lang==='fr'?'https://www.wasteconnectionscanada.com/fr/nos-services/conditions/':(country==='canada'&&lang==='en')||(country==='canada'&&!lang)?'https://www.wasteconnectionscanada.com/terms-of-use/':'https://www.wasteconnections.com/terms-of-use/')"
        :privacy="privacy||(country==='canada'&&lang==='fr'?'https://www.wasteconnectionscanada.com/fr/nos-services/confidentialite/':(country==='canada'&&lang==='en')||(country==='canada'&&!lang)?'https://www.wasteconnectionscanada.com/privacy-policy/':'https://www.wasteconnections.com/privacy-policy/')"
        :name="name||'Waste Connections'"
        :splitter="splitter||false" />
      </v-main>
    </v-app>
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld';
import Components from 'request-quote-cmp';

export default {
  name: 'App',
  props: {
    service: String,
    serviceicons: Boolean,
    assigntodistrict: Number,
    storefrontDistrictCodeOverride: Number,
    storefrontBypassLob: Boolean,
    lang: String,
    primary: String,
    secondary: String,
    phone: String,
    country: String,
    terms: String,
    privacy: String,
    name: String,
  },
  components: {
    // HelloWorld,
    ...Components
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,400&display=swap');
@import url('https://cdn.jsdelivr.net/npm/@mdi/font@5.x/css/materialdesignicons.min.css');
[quote-resi-data-vuetify]{overflow-y:hidden!important}
#app{font-family: Roboto, Helvetica, Arial, sans-serif;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;text-align: center;}
.grecaptcha-badge{visibility:hidden}
.pac-item{font-family:Roboto,sans-serif !important;font-weight:400 !important;color:rgba(0,0,0,.87) !important;line-height:40px !important;font-size:16px;cursor:pointer}
.pac-item-query{font-family:Roboto,sans-serif !important;font-size:16px}
.pac-item:hover{background-color:rgb(246,246,246) !important;transition: .3s cubic-bezier(.25,.8,.5,1)}
.pac-container{color:rgba(0,0,0,.87)!important;max-height:166px;overflow-y:auto}
.pac-icon,.pac-icon-marker{width:0 !important;height:0 !important;background:none !important;background-image:none !important}
.transparent{background-color:transparent !important;border-color:transparent !important}
.row>*{margin-left:initial!important}
div>.rounded{border-radius:4px!important}
div.container--fluid{padding:0px!important}
div.v-application--wrap{min-height:100%!important}
</style>
